import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";
import Home from "./components/Home";
import PageNotFound from "./PageNotFound";
import StudentDashBoard from "./student/StudentDashboard";
import SignIn from "./components/SignIn";
import SignUp from "./components/SignUp";

// firebase

import firebase from "firebase/app";
import firebaseConfig from "./firebaseConfig";
import { ToastContainer } from "react-toastify";

function App() {
  firebase.initializeApp(firebaseConfig);
  return (
    <Router>
      <ToastContainer position="top-center" />
      <Switch>
        <Route exact path="/signin" component={SignIn} />
        <Route exact path="/signup" component={SignUp} />
        <Route exact path="/contact/add" component={Home} />
        <Route exact path="/contact/view" component={Home} />
        <Route exact path="/student" component={StudentDashBoard} />
        <Route exact path="/" component={Home} />
        <Route exact path="*" component={PageNotFound} />
      </Switch>
    </Router>
  );
}

export default App;
