import React from "react";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";
import MenuAppBar from "../base/MenuAppBar";
import { Chip } from "@material-ui/core";
import CallIcon from "@material-ui/icons/Call";
import MailIcon from "@material-ui/icons/Mail";
import FacebookIcon from "@material-ui/icons/Facebook";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://www.chaitnya.in">
        Chaitnya
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
  chip: {
    cursor: "pointer",
    marginBottom: 5
  },
}));

const cards = [1, 2, 3, 4, 5, 6, 7, 8, 9];

export default function Album() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <CssBaseline />
      <MenuAppBar />
      <main>
        {/* Hero unit */}
        <div className={classes.heroContent}>
          <Container maxWidth="sm">
            <Grid container justify="center">
              <Grid item>
                <img
                  alt="logo"
                  src={process.env.PUBLIC_URL + `/logo192.png`}
                  imageStyle={{ width: "50px", height: "50px" }}
                />
              </Grid>
            </Grid>


            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="textPrimary"
              gutterBottom
            >
              Chaitnya Public School
            </Typography>
            <Grid
              container
              justify="space-evenly"
              direction="column"
              alignItems="center"
            >
              <Grid Item>
                <Link href="tel:9418682360">
                  <Chip
                    color="primary"
                    icon={<CallIcon />}
                    label={9418682360}
                    className={classes.chip}
                  />
                </Link>
              </Grid>
              <Grid Item>
                <Link href="mailto:cpslarha.2004@gmail.com">
                  <Chip
                    
                    color="primary"
                    icon={<MailIcon />}
                    label={"cpslarha.2004@gmail.com"}
                    className={classes.chip}
                  />
                </Link>
              </Grid>
              <Grid Item>
                <Link href="https://www.facebook.com/Chaitnya-Public-School-1013034982051946/" target="_blank" rel="noopener noreferrer">
                  <Chip
                
                    color="primary"
                    icon={<FacebookIcon />}
                    label={"Chaitnya"}
                    className={classes.chip}
                  />
                </Link>
              </Grid>
            </Grid>
            <Typography
              variant="h5"
              align="center"
              color="textSecondary"
              paragraph
            >
              The only person who is educated is the one who has learned how to
              learn and change
            </Typography>
          </Container>
        </div>
        <Container className={classes.cardGrid} maxWidth="md">
          {/* End hero unit */}
          <Grid container spacing={4}>
            {cards.map((card) => (
              <Grid item key={card} xs={12} sm={6} md={4}>
                <Card className={classes.card}>
                  <CardMedia
                    className={classes.cardMedia}
                    image={process.env.PUBLIC_URL + `/home${card}.jpg`}
                    title="Image title"
                  />
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </main>
      {/* Footer */}
      <footer className={classes.footer}>
        <Typography
          variant="subtitle1"
          align="center"
          color="textSecondary"
          component="p"
        >
          Managed and Created by Chirag Sharma
        </Typography>
        <Copyright />
      </footer>
      {/* End footer */}
    </React.Fragment>
  );
}
