import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import StudentBase from "../base/StudentBase";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles({
	table: {
		borderColor: "black",
		borderStyle: "solid",
		margin: "2px",
		width: "60vw",
		height: "10vh",
	},
});

function createData(sno, subject, time, action) {
	return { sno, subject, time, action };
}

const rows = [
	createData(1, "English", "03/09/2020 10:30", "Join"),
	createData(2, "English", "03/09/2020 10:30", "Join"),
	createData(3, "English", "03/09/2020 10:30", "Join"),
	createData(4, "English", "03/09/2020 10:30", "Join"),
	createData(5, "English", "03/09/2020 10:30", "Join"),
];
const StudentDashBoard = () => {
	const classes = useStyles();
	return (
		<StudentBase>
			<Grid item>
				<TableContainer>
					<Table
						// style={{ margin: 2, width: "80vw", height: "40vh" }}
						variant='outlined'
						className={classes.table}
						aria-label='simple table'>
						<TableHead>
							<TableRow>
								<TableCell>Subject</TableCell>
								<TableCell>Time</TableCell>
								<TableCell>Action</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{rows.map((row) => (
								<TableRow key={row.sno}>
									<TableCell component='th' scope='row'>
										{row.subject}
									</TableCell>
									<TableCell>{row.time}</TableCell>
									<TableCell>
										<Button variant='contained' color='primary'>
											Join
										</Button>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Grid>
		</StudentBase>
	);
};

export default StudentDashBoard;
