import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import VideoCallIcon from "@material-ui/icons/VideoCall";
import AssignmentIcon from "@material-ui/icons/Assignment";
import AssessmentIcon from "@material-ui/icons/Assessment";
import { Typography } from "@material-ui/core";

const SideList = () => {
	return (
		<div>
			<List>
				<ListItem>
					<Typography variant='h6'>Chaitnya</Typography>
				</ListItem>
				{["Video Conference(beta)", "Assignments (beta)", "Result Card (beta)"].map(
					(text, index) => (
						<ListItem button key={text}>
							<ListItemIcon>
								{index === 0 ? (
									<VideoCallIcon />
								) : index === 1 ? (
									<AssignmentIcon />
								) : index === 2 ? (
									<AssessmentIcon />
								) : (
									""
								)}
							</ListItemIcon>
							<ListItemText primary={text} />
						</ListItem>
					)
				)}
			</List>
		</div>
	);
};

export default SideList;
