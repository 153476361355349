import React from "react";
import MenuAppBar from "./MenuAppBar";
import { Chip, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import FaceIcon from "@material-ui/icons/Face";

const useStyles = makeStyles((theme) => ({
	chip: {
		margin: theme.spacing(0.5),
	},
}));

const StudentBase = ({
	name = "Chirag Sharma",
	rollNo = 4,
	classs = "7th",
	children,
}) => {
	const classes = useStyles();

	return (
		<div>
			<MenuAppBar showMenu={true} showLogin={false}/>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Grid container justify='center'>
						<Chip
							variant='outlined'
							color='primary'
							icon={<FaceIcon />}
							label={name}
							className={classes.chip}
						/>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<Grid container justify='center'>
						<Chip
							variant='outlined'
							color='primary'
							label={"RollNo -" + rollNo}
							className={classes.chip}
						/>
						<Chip
							variant='outlined'
							color='primary'
							label={"Class -" + classs}
							className={classes.chip}
						/>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<Grid container justify='center'>
						{children}
					</Grid>
				</Grid>
			</Grid>
		</div>
	);
};

export default StudentBase;
