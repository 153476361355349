import React from "react";

const PageNotFound = () => {
	return (
		<div>
			<h1>404 page not found</h1>
		</div>
	);
};

//FIXME: missing keywords
export default PageNotFound;
