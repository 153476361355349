const firebaseConfig = {
  apiKey: "AIzaSyDTtvAQl8_3YYOox46wFxD-noF6WyW-e3U",
  authDomain: "cpslogin-236bd.firebaseapp.com",
  databaseURL: "https://cpslogin-236bd.firebaseio.com",
  projectId: "cpslogin-236bd",
  storageBucket: "cpslogin-236bd.appspot.com",
  messagingSenderId: "357792397479",
  appId: "1:357792397479:web:ef58ec6a019d87d681c382",
};

export default firebaseConfig;
